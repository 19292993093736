<template>
  <v-layout column justify-center fill-height class="text-input-mission">
    <!-- Mission question -->
    <v-flex d-flex class="mission-instructions">
      <ResizableText :message="instructions" />
      <v-btn
        circle
        absolute
        small
        left
        fab
        v-if="isHost && !teamSubmit && isDrawing"
        class="mission-over"
        icon
        @click="missionOver()"
      >
        <v-icon>done</v-icon>
      </v-btn>
    </v-flex>
    <v-flex shrink>
      <TextField
        class="mission-text-feild"
        :placeholder="placeholder"
        :status="status"
        :show="showTextField"
        :buttonText="'SUBMIT'"
        @onSubmit="onSubmit"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "./ResizableText.vue"
import TextField from "./TextField.vue"
import { EventBus } from "@/Eventbus"

const DRAWING_PICTIONARY = "Draw: Pictionary"

export default {
  name: "DrawingTextInput",
  mixins: [GameMixin],
  components: {
    ResizableText,
    TextField
  },
  props: {
    mode: String
  },
  watch: {
    teamSubmit() {
      this.submitting = true
      this.answer = " "
      if (!this.isHost && this.isScribe)
        EventBus.$emit("drawingSubmit", this.answer)
    }
  },
  data() {
    return {
      waitingText: "Waiting on Scribe...",
      lastIncorrectPlays: 0,
      submitting: false
    }
  },
  created() {
    EventBus.$on("drawingResponse", this.onDrawingResponse)
  },
  beforeDestroy() {
    EventBus.$off("drawingResponse", this.onDrawingResponse)
  },
  computed: {
    ...mapState({
      teams: state => state.chat.chats
    }),
    ...mapGetters("auth", ["isHost"]),
    ...mapState("drawing", { assignedTeam: "teamID" }),
    ...mapGetters([
      "missionFail",
      "missionSuccess",
      "missionCompleted",
      "missionPlayType",
      "missionAnswers",
      "getCurrentMission",
      "isScribe",
      "teamID",
      "team"
    ]),
    teamsArr() {
      if (this.teams) return Object.values(this.teams)
      return null
    },
    teamSubmit() {
      return this.teams[this.currentGlobalTeam].submit
    },
    answerPic() {
      return this.currentMission.answer
    },
    assignedPicTeam() {
      return this.assignedTeam && this.teams
        ? this.teams[this.assignedTeam]
        : null
    },
    assignedPicTeamName() {
      return this.assignedPicTeam ? this.assignedPicTeam.name : null
    },
    instructions() {
      if (this.isPictionary) {
        if (this.isHost) {
          if (this.assignedTeam) {
            return `${this.assignedPicTeamName} is drawing a "${this.answerPic}"`
          } else {
            return `Select team to draw a ${this.answerPic}`
          }
        }
        if (this.assignedTeam) {
          if (this.teamID === this.assignedTeam) {
            return `Your team is drawing: <br/> "${this.answerPic}"`
          } else {
            return `Guess what "${this.assignedPicTeamName}" is drawing`
          }
        } else {
          return "Waiting on assignment"
        }
      } else {
        return this.currentMission.instructions || ""
      }
    },
    correct() {
      return this.getCurrentMission ? this.getCurrentMission.answer : null
    },
    lineThroughClass() {
      return this.missionFail ||
        (this.missionAnswersLength > 0 && !this.missionSuccess)
        ? " line-through"
        : ""
    },
    missionAnswersLength() {
      return this.missionAnswers.length
    },
    isTeamMission() {
      return this.missionPlayType === "team"
    },
    status() {
      const {
        isHost,
        missionCompleted,
        isScribe,
        canPlay,
        lineThroughClass,
        submitting
      } = this
      if (this.isPictionary) {
        if (this.gotItRight) {
          return "correct"
        } else if (!isHost && !missionCompleted && isScribe && canPlay) {
          return "active" + lineThroughClass
        }
      } else {
        if (submitting || missionCompleted) {
          return "correct"
        } else if (isScribe) {
          return "active"
        } else {
          return "active"
        }
      }
      return ""
    },
    showTextField() {
      if (this.isPictionary && this.isAssignedTeam) return ""
      if (this.isPictionary && !this.assignedTeam) return ""
      return this.mode !== "explain" ? "show" : ""
    },
    canPlay() {
      return (
        this.mode === "play" || this.mode === "huddle" || this.mode === "social"
      )
    },
    isAssignedTeam() {
      return this.user.teamID === this.assignedTeam
    },
    isPictionary() {
      return this.currentMission.behavior === DRAWING_PICTIONARY
    },
    isDrawing() {
      return this.currentMission.behavior === "Draw: Each Team"
    },
    placeholder() {
      const {
        isHost,
        correct,
        isScribe,
        waitingText,
        isTeamMission,
        missionAnswers,
        missionAnswersLength,
        missionSuccess,
        missionCompleted,
        missionFail,
        canPlay
      } = this
      if (isHost) {
        // always show the asnwer to the host
        return correct
      } else if (!isScribe && !missionAnswersLength) {
        // no data entered for a non scribe
        return waitingText
      } else if (isTeamMission && missionAnswersLength && !missionCompleted) {
        // set the placeholder message for a non scribe
        // to the latest enetered by a scribe
        const answer = missionAnswers[missionAnswersLength - 1]
        return typeof answer === "string" ? answer : ""
      } else if (missionSuccess) {
        return "Correct!"
      } else if (missionFail) {
        return correct
      } else if (isScribe && canPlay) {
        if (this.isPictionary) {
          if (this.isAssignedTeam) {
            return null
          } else {
            return "Enter here..."
          }
        } else {
          return "Caption"
        }
      } else {
        return ""
      }
    }
  },
  methods: {
    missionOver() {
      let teams = Object.keys(this.teams)
      let obj = {}
      for (var i in teams) {
        obj.submit = true
        obj.id = teams[i]
        // console.log("TEAM UPDATE", obj)
        this.$store.dispatch("updateTeam", obj)
      }
    },
    async onDrawingResponse({ caption, url }) {
      if (this.isPictionary) return
      // updating some mixing props here (I guess)
      this.imageUrl = url
      this.hasImage = true
      this.caption = caption
      await this.checkAnswer()
      this.answer = ""
    },
    async onSubmit(data) {
      if (!this.isScribe && !this.isHost)
        return console.log("No rights for submit")
      if (this.isPictionary) {
        if (!this.isAssignedTeam) {
          this.submitting = true
          this.answer = data
          await this.checkAnswer()
          this.answer = ""
        } else {
          return console.log("No rights for submit")
        }
      } else {
        console.log("onSubmit")
        if (this.submitting) return console.log("No rights for submit")
        this.submitting = true
        this.answer = data
        EventBus.$emit("drawingSubmit", data)
      }
    }
  }
}
</script>

<style lang="scss">
.mission-over {
  margin-top: -25px;
  margin-left: -10px;
  background-color: red;
}
.text-input-mission {
  .mission-instructions {
    padding: 25px 10px 5px;
  }
  .mission-text-feild {
    position: relative;
    margin-left: 60px;
    margin-right: 6px;
    margin-bottom: 6px;
    transform: translateY(10px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-out;
  }
}
</style>
